import loadable from "@loadable/component"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { Helmet } from "react-helmet"
import { connect } from "react-redux"
import { Redirect, Switch } from "react-router-dom"

import { setExperiment } from "app-store-v2/app/actions"
import Footer from "areas/footer/jewlr"
import Header from "areas/header/jewlr"
import JsCookiesWarning from "areas/header/js-cookies-warning"
import FakeBeacon from "areas/helpdesk/fakeBeacon"
import CLSMonitor from "components/cls-monitor"
import Hreflang from "components/hreflang"
import MerchantLoading from "components/merchant-loading"
import SkipToMainContent from "components/skip-to-main-content"
import HelpDeskProvider from "contexts/helpdesk"
import { IS_CA, jewlr_t, retry, getMetaDescription } from "helpers/application"
import { Route } from "helpers/sentry"
import fetchUser from "hoc/account/fetch-user"
import { GlobalStyle } from "styles/global"

const loadableDefaultOptions = {
  fallback: <MerchantLoading />,
}

const InfoRoute = loadable(
  () => retry(() => import("areas/info/jewlr/route")),
  loadableDefaultOptions
)
const ArticleLanding = loadable(
  () => retry(() => import("areas/article/landing")),
  loadableDefaultOptions
)
const Cart = loadable(
  () => retry(() => import("areas/cart")),
  loadableDefaultOptions
)
const LoadCollection = loadable(
  () => retry(() => import("areas/collection/load-collection")),
  loadableDefaultOptions
)
const Helpdesk = loadable(
  () => retry(() => import("areas/helpdesk/helpdesk")),
  loadableDefaultOptions
)
const LegacyHomepage = loadable(
  () => retry(() => import("areas/homepage/jewlr/legacy-homepage")),
  loadableDefaultOptions
)
const LandingWatches = loadable(
  () => retry(() => import("areas/info/jewlr/watches/landing")),
  loadableDefaultOptions
)
const DiamondStudEarrings = loadable(
  () => retry(() => import("areas/landing/diamond-stud-earrings/index")),
  loadableDefaultOptions
)
const Product = loadable(
  () => retry(() => import("areas/product/jewlr")),
  loadableDefaultOptions
)

const CollectionsForHer = loadable(
  () => retry(() => import("jewlr/pages/collections-for-her")),
  loadableDefaultOptions
)
const CollectionsForHim = loadable(
  () => retry(() => import("jewlr/pages/collections-for-him")),
  loadableDefaultOptions
)
const GetInspired = loadable(
  () => retry(() => import("jewlr/pages/get-inspired")),
  loadableDefaultOptions
)
const Account = loadable(
  () => retry(() => import("areas/account")),
  loadableDefaultOptions
)
const StandaloneReferral = loadable(
  () => retry(() => import("areas/account/referrals/standalone")),
  loadableDefaultOptions
)
const PasswordReset = loadable(
  () => retry(() => import("areas/password-reset")),
  loadableDefaultOptions
)
const Checkout = loadable(
  () => retry(() => import("areas/checkout")),
  loadableDefaultOptions
)
const Complete = loadable(
  () => retry(() => import("areas/checkout/order-complete/jewlr")),
  loadableDefaultOptions
)
const Contact = loadable(
  () => retry(() => import("areas/contact-form")),
  loadableDefaultOptions
)
const Order = loadable(
  () => retry(() => import("areas/order")),
  loadableDefaultOptions
)
const Payment = loadable(
  () => retry(() => import("areas/payments")),
  loadableDefaultOptions
)
const Info = loadable(
  () => retry(() => import("areas/info/jewlr")),
  loadableDefaultOptions
)
const AccountRegister = loadable(
  () => retry(() => import("areas/authenticate/register")),
  loadableDefaultOptions
)
const EmailPreferences = loadable(
  () => retry(() => import("areas/account/email-preferences")),
  loadableDefaultOptions
)
const ResubscribedComplete = loadable(
  () =>
    retry(() =>
      import("areas/account/email-preferences/resubscribed-complete")
    ),
  loadableDefaultOptions
)
const UnsubscribedComplete = loadable(
  () =>
    retry(() =>
      import("areas/account/email-preferences/unsubscribed-complete")
    ),
  loadableDefaultOptions
)
const Login = loadable(
  () => retry(() => import("areas/authenticate/login")),
  loadableDefaultOptions
)
const EmailSignUp = loadable(
  () => retry(() => import("areas/email-signup")),
  loadableDefaultOptions
)
const Tagger = loadable(
  () => retry(() => import("areas/tagger")),
  loadableDefaultOptions
)
const AboutUs = loadable(
  () => retry(() => import("areas/about-us/jewlr")),
  loadableDefaultOptions
)
const Covid = loadable(
  () => retry(() => import("areas/info/jewlr/covid")),
  loadableDefaultOptions
)
const InstantGiftDemo = loadable(
  () => retry(() => import("areas/instant-gift/explained/demo")),
  loadableDefaultOptions
)
const InstantGift = loadable(
  () => retry(() => import("areas/instant-gift/item")),
  loadableDefaultOptions
)
const PreviewGift = loadable(
  () => retry(() => import("areas/instant-gift/item/preview-gift")),
  loadableDefaultOptions
)
const Reviews = loadable(
  () => retry(() => import("areas/info/reviews")),
  loadableDefaultOptions
)
const NWVibe = loadable(
  () => retry(() => import("jewlr/main/nw-vibe")),
  loadableDefaultOptions
)
const Vibe = loadable(
  () => retry(() => import("jewlr/main/vibe")),
  loadableDefaultOptions
)
const AttentiveSignup = loadable(
  () => retry(() => import("jewlr/pages/attentive-signup")),
  loadableDefaultOptions
)
const GenericNotFound = loadable(
  () => retry(() => import("app/generic-not-found")),
  loadableDefaultOptions
)
const GenericProductImport = loadable(
  () => retry(() => import("app/generic-product-import")),
  loadableDefaultOptions
)
const VipRewards = loadable(
  () => retry(() => import("areas/vip-rewards/jewlr")),
  loadableDefaultOptions
)
const Wishlist = loadable(
  () => retry(() => import("areas/wishlist")),
  loadableDefaultOptions
)

/**
 * The main App wrapper. This contains the top-level routing and passes through `data` when appropriate to children
 * components
 * @param  {object} options.match    For React Router
 * @param  {object} options.history  For React Router
 */

const InternalApp = ({
  article_data: articleData,
  checkout,
  complete,
  gift_data: giftData,
  handleSkipToMainContent,
  helpdesk,
  helpdesk_article: helpdeskArticle,
  helpdesk_collection: helpdeskCollection,
  helpdesk_homepage: helpdeskHomepage,
  helpdesk_related_articles: helpdeskRelatedArticles,
  menu,
  navTopRef,
  order_update_data: orderUpdateData,
  path,
  product_import: productImportData,
  setExperiment,
  signUpFormExp,
  skippedRef,
  tagger: taggerData,
  user_agent: userAgent,
}) => {
  useEffect(() => {
    const triggerSignUpFormExp = () => {
      if (signUpFormExp) {
        setExperiment({ signUpForm: signUpFormExp })
      }
      window.removeEventListener("click", triggerSignUpFormExp)
      window.removeEventListener("scroll", triggerSignUpFormExp)
      window.removeEventListener("touchstart", triggerSignUpFormExp)
    }

    window.addEventListener("click", triggerSignUpFormExp)
    window.addEventListener("scroll", triggerSignUpFormExp)
    window.addEventListener("touchstart", triggerSignUpFormExp)

    return () => {
      window.removeEventListener("click", triggerSignUpFormExp)
      window.removeEventListener("scroll", triggerSignUpFormExp)
      window.removeEventListener("touchstart", triggerSignUpFormExp)
    }
  }, [signUpFormExp])

  return (
    <>
      <Helmet titleTemplate="%s | Jewlr">
        <title>{`${jewlr_t("terms.Personalized")} ${jewlr_t(
          "terms.Jewelry"
        )} | Custom Gemstones & Engravings`}</title>
        <meta content={getMetaDescription()} name="description" />
        <meta content={getMetaDescription()} property="og:description" />
      </Helmet>
      <GlobalStyle />
      <Hreflang />
      <span ref={navTopRef} tabIndex="-1" />
      <SkipToMainContent handleSkipToMainContent={handleSkipToMainContent} />
      <Header menu={menu} />
      <main>
        <span ref={skippedRef} tabIndex="-1" />
        <JsCookiesWarning />
        <Switch>
          <Redirect from="//*" to="/*" />
          {!sl.config.enable_homepage_landing && (
            <Route component={LegacyHomepage} path="/homepage" />
          )}
          <Route component={AboutUs} exact path="/about-us" />
          <Route component={VipRewards} exact path="/vip-rewards" />
          {!sl.config.enable_homepage_landing && (
            <Route component={LegacyHomepage} exact path="/" />
          )}
          {sl.config.env !== "production" && (
            <Route component={LegacyHomepage} exact path="/legacy-homepage" />
          )}
          <Route
            path="/ask/"
            render={() => {
              if (sl.config.env == "production") return ""
              else {
                return (
                  <>
                    <iframe
                      height="1500px"
                      src={"https://ask.jewlr.com"}
                      width="100%"
                    />
                  </>
                )
              }
            }}
          />
          <Route
            exact
            path="/email-preferences/unsubscribe-complete"
            render={() => <UnsubscribedComplete />}
          />
          <Route
            exact
            path={"/email-preferences/subscribe-complete"}
            render={() => <ResubscribedComplete />}
          />
          <Route
            exact
            path={[
              "/email-preferences/:guidToken",
              "/email-unsubscribe/:guidToken",
            ]}
            render={({ match, staticContext }) => (
              <EmailPreferences
                match={match}
                standalone
                staticContext={staticContext}
              />
            )}
          />
          <Route path="/account" render={() => <Account />} />
          <Route
            path="/referral/:guidToken"
            render={({ match }) => <StandaloneReferral match={match} />}
          />
          <Route component={PasswordReset} path="/password-reset" />
          <Route
            exact
            path="/checkout"
            render={() => <Checkout checkout={checkout} />}
          />
          <Route
            exact
            path="/checkout/complete"
            render={() => <Complete complete={complete} />}
          />
          <InfoRoute
            component={Contact}
            exact
            newLayout
            path="/contact_form"
            title="Contact Us"
          />
          <Route component={Login} path={"/signin"} />
          <Route component={AccountRegister} path="/signup" />
          <Route component={AccountRegister} exact path={"/register"} />
          <Route component={NWVibe} path="/nwvibe" />
          <Route component={Vibe} path="/vibe" />
          <Route component={InstantGiftDemo} exact path="/gift/demo" />
          <Route
            path="/gift/share/:lineItemCode"
            render={({ match }) => (
              <PreviewGift
                giftData={giftData}
                lineItemCode={match.params.lineItemCode}
                skipVideo
              />
            )}
          />
          <Route
            exact
            path="/gift/:lineItemCode"
            render={({ match }) => (
              <InstantGift
                giftData={giftData}
                lineItemCode={match.params.lineItemCode}
              />
            )}
          />
          <Route
            path="/order"
            render={() => <Order orderUpdateData={orderUpdateData} />}
          />
          <InfoRoute
            component={Reviews}
            newLayout
            path="/reviews"
            showCta
            title="Customer Reviews"
          />
          {sl.config.enable_watches && (
            <Route
              component={LandingWatches}
              path="/about-jewlr-watches"
              title="About Jewlr Watches"
            />
          )}
          <Route
            component={DiamondStudEarrings}
            exact
            path="/diamond-stud-earrings-landing"
          />
          {!IS_CA() && (
            <InfoRoute
              component={Covid}
              newLayout
              path="/covid19"
              title="COVID-19"
            />
          )}
          <Route component={Info} path="/info" />
          <Route
            path="/cart"
            render={({ history }) => <Cart history={history} />}
          />
          {sl.config.env !== "production" && (
            <Route
              path="/products/tagger"
              render={({ location }) => (
                <Tagger location={location} taggerData={taggerData} />
              )}
            />
          )}
          <Route
            path="/products/share/:styleCode"
            render={({ history, location, match }) => (
              <Product history={history} location={location} match={match} />
            )}
          />
          <Route
            path="/products/:styleCode"
            render={({ history, location, match }) => {
              return (
                <Product history={history} location={location} match={match} />
              )
            }}
            skipPageView
          />
          <Route
            path="/payments/:id"
            render={({ history, location, match }) => {
              return (
                <Payment history={history} location={location} match={match} />
              )
            }}
          />
          <Route
            path={"/helpdesk"}
            render={({ location, match, staticContext }) => {
              return (
                <HelpDeskProvider>
                  <Helpdesk
                    helpdesk={helpdesk}
                    helpdeskArticle={helpdeskArticle}
                    helpdeskCollection={helpdeskCollection}
                    helpdeskHomepage={helpdeskHomepage}
                    helpdeskRelatedArticles={helpdeskRelatedArticles}
                    location={location}
                    match={match}
                    staticContext={staticContext}
                  />
                </HelpDeskProvider>
              )
            }}
          />
          <Route
            path="/blog"
            render={() => <ArticleLanding articleData={articleData} />}
          />
          {(sl.config.site_country === "us" ||
            sl.config.site_country === "ca") && (
            <Route component={AttentiveSignup} path="/attn-signup" />
          )}

          <Route component={CollectionsForHim} path="/collections-for-him" />
          <Route
            path="/collections-for-her"
            render={() => <CollectionsForHer userAgent={userAgent} />}
          />
          <Route component={GetInspired} path="/get-inspired" />
          <Route component={EmailSignUp} path="/email-signup" />
          <Route component={Wishlist} path="/wishlist" />
          <Route
            exact
            path="/:collection?"
            render={({ history, location, match, staticContext }) => (
              <LoadCollection
                history={history}
                location={location}
                match={match}
                path={path}
                staticContext={staticContext}
              />
            )}
            skipPageView
          />
          <Route
            path="/product_import/jobs/:id"
            render={() => (
              <GenericProductImport productImportData={productImportData} />
            )}
          />
          <Route component={GenericNotFound} />
        </Switch>
      </main>
      <Footer />
      <FakeBeacon />
      {sl.config.env !== "production" && <CLSMonitor />}
    </>
  )
}

InternalApp.propTypes = {
  article_data: PropTypes.object,
  checkout: PropTypes.object,
  complete: PropTypes.object,
  gift_data: PropTypes.object,
  handleSkipToMainContent: PropTypes.func,
  helpdesk: PropTypes.object,
  helpdesk_article: PropTypes.object,
  helpdesk_collection: PropTypes.object,
  helpdesk_homepage: PropTypes.object,
  helpdesk_related_articles: PropTypes.array,
  menu: PropTypes.object,
  navTopRef: PropTypes.object,
  order_update_data: PropTypes.object,
  path: PropTypes.string,
  product_import: PropTypes.object,
  setExperiment: PropTypes.func,
  signUpFormExp: PropTypes.object,
  skippedRef: PropTypes.object,
  tagger: PropTypes.object,
  user_agent: PropTypes.string,
}

const mapStateToProps = (state) => {
  return {
    signUpFormExp: state.experiment.signUpForm,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setExperiment: (exp) => dispatch(setExperiment(exp)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(fetchUser(InternalApp))
