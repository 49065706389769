import {
  Box,
  Divider,
  Flex,
  Image,
  MediaBox,
  MediaFlex,
  Shimmer,
} from "@jewlr/storybook/core"
import React from "react"
import styled, { keyframes } from "styled-components"

import { GridContainer } from "areas/collection2/products"
import ItemContainer from "areas/collection2/products/item-container"

import FilterAccordionLoading from "./filter-accordion-loading"

const shimmer = keyframes`
  from {
    background-position: -256px 0;
  }
  to {
    background-position: 256px 0;
  }
`

const StyledImage = styled(Image)`
  animation: ${shimmer} 1.5s forwards infinite;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  height: auto;
  max-width: 100%;
  overflow: hidden;
  ${({ theme }) => theme.mediaQueries.tablet`
    width: 100%;
    max-width: 256px;
  `}
  ${({ theme }) => theme.mediaQueries.widescreen`
    max-width: 300px;
  `}
`

const BannerShimmer = styled(Box)`
  aspect-ratio: 750 / 708;
  ${(props) => props.theme.mediaQueries.tablet`
    aspect-ratio: 2560 / 376;
  `}
`

const Shimmers = () => (
  <Box containWide>
    <BannerShimmer>
      <Shimmer height="100%" width="100%" />
    </BannerShimmer>
    <Box px={2}>
      <Box my={2} py={2}>
        <Shimmer height="17px" mb={2} width="150px" />
        <Flex justifyContent="space-between">
          <Box width={{ desktop: "75%" }}>
            <Shimmer
              height="30px"
              mb={{ _: 2, desktop: 1 }}
              width={{ _: "200px", desktop: "400px" }}
            />
            <Shimmer
              height={{ _: "12px", desktop: "20px" }}
              width={{ _: "300px", desktop: "600px" }}
            />

            <MediaBox lessThan="desktop">
              <Shimmer height="12px" mt={1} width="300px" />
            </MediaBox>
          </Box>

          <MediaBox greaterThanOrEqual="desktop">
            <Shimmer height="20px" width="130px" />
          </MediaBox>
        </Flex>
      </Box>
      <Divider bg="grey.10" />
      <MediaFlex justifyContent="space-between" lessThan="desktop" p={1}>
        <Shimmer height="20px" width="70px" />
        <Shimmer height="20px" width="135px" />
      </MediaFlex>
      <Flex justifyContent="center">
        <FilterAccordionLoading />
        <Box flex="1">
          <GridContainer>
            {[...Array(12)].map((_, i) => (
              <ItemContainer key={`shimmerplacholder${i}`}>
                <StyledImage
                  alt="placeholder ring"
                  ratio="auto 1 / 1"
                  src={require(`images/collections/placeholder_masks/ring_${
                    (i % 6) + 1
                  }.svg`)}
                />
              </ItemContainer>
            ))}
          </GridContainer>
        </Box>
      </Flex>
    </Box>
  </Box>
)

export default Shimmers
