import { Box, Flex, Image } from "@jewlr/storybook/core"
import loadable from "@loadable/component"
import PropTypes from "prop-types"
import queryString from "query-string"
import React, { useEffect } from "react"
import Helmet from "react-helmet"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import LandingElement from "areas/landing/landing-element"
import IntersectionWrapper from "components/common/intersection-wrapper"
import { openModal } from "components/modal"
import { isTestMode, retry } from "helpers/application"
import withLanding from "hoc/landing/with-landing"

const HomepageBannerCarousel = loadable(() =>
  retry(() => import("./homepage-banner-carousel"))
)
const LandingHeroBanner = loadable(() =>
  retry(() => import("areas/landing/elements/hero-banner"))
)

const Homepage = ({
  bannerIds,
  heroBanners,
  landing,
  location,
  // shuffledIds,
}) => {
  useEffect(() => {
    if (!isTestMode) {
      submit_ga_event_click("Page", "Viewed Homepage")
      dataLayer.push({
        event: "viewed-homepage",
      })
    }
  }, [])

  useEffect(() => {
    if (typeof window !== "undefined") {
      const signin = queryString.parse(location.search).signin === "true"

      if (signin) {
        const user_obj = window.localStorage.getItem("user_obj")
        const loggedIn =
          user_obj && Object.keys(JSON.parse(user_obj)).length > 0

        if (!loggedIn) {
          openModal("#authenticate")
        }
      }
    }
  }, [])

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{`
       {
          "@context": "http://schema.org",
          "@type": "Organization",
          "url": "${`https://${sl.config.site_url}`}",
          "name": "Jewlr",
          "logo": "https://assets.jewlr.com/images/logos/jewlr-logo.svg",
          "email": "${sl.config.customer_service_email}",
          "contactPoint": [
            {
              "@type": "ContactPoint",
              "email": "${sl.config.customer_service_email}",
              "telephone": "${sl.config.customer_service_toll_free}",
              "contactType": "customer service"
            }
          ],
          "sameAs": [
            "https://www.facebook.com/jewlrcom",
            "https://www.instagram.com/jewlr/",
            "https://www.youtube.com/channel/UC11M7JozPazDfSi4dMf_wOg",
            "https://www.pinterest.com/jewlrcom/_shop/"
          ]
        }
      `}</script>

        <script type="application/ld+json">{`
        {
          "@context": "http://schema.org",
          "@type": "WebSite",
          "url": "${`https://${sl.config.site_url}`}",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "${`https://${sl.config.site_url}`}/search?k={search_term}",
            "query": "required",
            "query-input": "required name=search_term"
          }
        }
      `}</script>
      </Helmet>
      {bannerIds?.length > 1 ? (
        <Box containWide>
          <HomepageBannerCarousel banners={heroBanners} order={bannerIds} />
        </Box>
      ) : bannerIds?.length == 1 ? (
        <LandingHeroBanner
          element={heroBanners && Object.values(heroBanners)[0]}
          lazyload={false}
        />
      ) : null}
      {landing.elements_list.map((el, index) => {
        const elId = el.anchor_name || el.id
        const element = landing.elements.find((elm) => elm.id === el.id)
        let content

        if (element) {
          // SSR (Pre-render) the first THREE elements
          if (index <= 2) {
            content = (
              <LandingElement el={element} index={index} wrapTheChild={false} />
            )
          } else {
            // Lazy-loaded element
            content = (
              <IntersectionWrapper>
                <LandingElement
                  el={element}
                  index={index}
                  wrapTheChild={false}
                />
              </IntersectionWrapper>
            )
          }
        }

        return (
          <div id={elId} key={`homepage-wrapper-${index}`}>
            {content}
          </div>
        )
      })}
      {landing.fetching_elements_loading && (
        <Flex style={{ justifyContent: "center", marginBottom: "50px" }}>
          <Image
            alt="Loading"
            data-cy="collection-loading-icon"
            ratio="1 / 1"
            src={require("images/layout/loadinglarge.gif")}
            width="150px"
          />
        </Flex>
      )}
    </>
  )
}

Homepage.propTypes = {
  bannerIds: PropTypes.array,
  heroBanners: PropTypes.object,
  landing: PropTypes.object,
  location: PropTypes.object,
  // shuffledIds: PropTypes.array,
}

const mapStateToProps = (state) => {
  return {
    bannerIds: state.sale.homepage_hero_banner_ids,
    heroBanners: state.sale.homepage_hero_banners,
  }
}

export default connect(mapStateToProps)(withRouter(withLanding(Homepage)))
